<template>
  <div style="background-color: white;position: relative">
    <div style="margin: 0 2% 0 2%;">
      <div style="margin:0 0 4% 4%">
        <div style="height: 40px;position: relative;">
          <div class="blue-line"></div>
          <div class="base-info-label">基础信息</div>
        </div>
      </div>
      <div>
        <van-cell-group>
          <van-cell title="社区" v-model="detailList.communityName" />
          <van-cell title="服务类型" v-model="detailList.typeName"/>
          <van-cell title="服务名称" v-model="detailList.name"/>
          <van-cell title="请求者" v-model="detailList.userName"/>
          <van-cell title="请求时间" v-model="detailList.createDate"/>
          <van-cell title="请求理由" v-model="detailList.serviceReason" v-if="detailList.community == '15303'"/>
          <van-cell title="房屋" v-model="detailList.fullName"/>
          <van-cell title="联系电话" v-model="detailList.mobile"/>
          <van-cell title="审核时间" v-model="detailList.checkDate"/>
          <van-cell title="服务收益" v-if="detailList.serviceProfit===0" value="无偿"/>
          <van-cell title="服务收益" v-if="detailList.serviceProfit===1" value="低偿"/>
          <van-cell title="审核意见"  v-if="detailList.checkDes" style="" value=""/>
          <van-cell v-if="detailList.checkDes" v-model="detailList.checkDes" class="desc"></van-cell>
        </van-cell-group>
      </div>
      <!--大厅请求服务-->
      <div v-if="status===51">
        <div style="height: 80px;"></div>
        <van-button round class="blue-btn" @click="application">申请提供服务</van-button>
      </div>
      <!--待服务-->
      <div v-if="status===20">
        <!--        <div style="width: 100%;height: 8px;background-color: #F5F5F5;margin-top: 10px;"></div>-->
        <div style="margin:2% 0 4% 4%">
          <div style="height: 40px;position: relative;">
            <div class="blue-line"></div>
            <div class="base-info-label">服务信息</div>
          </div>
        </div>
        <div>
          <van-cell-group>
            <van-cell title="服务者" v-model="detailList.provideUserName" />
            <van-cell title="接单时间" v-model="detailList.provideDate"/>
            <van-cell title="服务单状态" v-model="detailList.statusName"/>
            <van-cell title="服务描述"/>
            <van-field v-model="message" row="2" type="textarea" placeholder="这是服务者的留言"></van-field>
            <van-row style="margin-top: 20px;margin-left: 4%;">
              <van-col>
                <van-uploader v-model="uploadFile" :multiple="false" @delete="uploadDel" :after-read="uploadRead"/>
              </van-col>
            </van-row>
          </van-cell-group>
        </div>
        <div style="height: 80px"></div>
        <div>
          <van-button round class="back-btn" @click="backApplication">无法完成，撤回</van-button>
          <van-button round color="#387ff5" class="finish-btn" @click="uploadResult">完成任务</van-button>
        </div>
      </div>
      <!--已评价-->
      <div v-if="status===40">
        <div style="margin:2% 0 4% 4%">
          <div style="height: 40px;position: relative;">
            <div class="blue-line"></div>
            <div class="base-info-label">服务信息</div>
          </div>
        </div>
        <div>
          <van-cell-group>
            <van-cell title="服务者" v-model="detailList.provideUserName" />
            <van-cell title="接单时间" v-model="detailList.provideDate"/>
            <van-cell title="服务时间" v-model="detailList.serviceDate"/>
            <van-cell title="服务描述" v-model="detailList.serviceDes"></van-cell>
            <div style="margin-left: 2%;display: flex;margin-top: 20px;">
              <van-uploader :multiple="false" :show-upload="false" :deletable="false" v-model="fileList" :after-read="uploadRead"></van-uploader>
            </div>
          </van-cell-group>
        </div>
        <div style="margin:2% 0 4% 4%">
          <div style="height: 40px;position: relative;">
            <div class="blue-line"></div>
            <div class="base-info-label">服务评价</div>
          </div>
        </div>
        <div>
          <van-cell-group>
            <van-cell title="评价">
              <van-rate color="#ffa93a" v-model="this.detailList.evaluate" />
            </van-cell>
            <van-cell title="评价时间" v-model="detailList.evaluateTime"/>
            <van-cell title="服务单状态" v-model="detailList.statusName"/>
          </van-cell-group>
        </div>
        <div style="height: 80px;"></div>
      </div>
      <!--待评价-->
      <div v-if="status===30">
        <div style="margin:2% 0 4% 4%">
          <div style="height: 40px;position: relative;">
            <div class="blue-line"></div>
            <div class="base-info-label">服务信息</div>
          </div>
        </div>
        <div>
          <van-cell-group>
            <van-cell title="服务者" v-model="detailList.provideUserName" />
            <van-cell title="接单时间" v-model="detailList.provideDate"/>
            <van-cell title="服务单状态" v-model="detailList.statusName"/>
            <van-cell title="服务时间" v-model="detailList.serviceDate"/>
            <van-cell title="服务描述"></van-cell>
            <van-cell class="desc" v-if="detailList.serviceDes" v-model="detailList.serviceDes"></van-cell>
            <div style="margin-left: 2%;display: flex;margin-top: 20px;">
              <van-uploader :multiple="false" :show-upload="false" :deletable="false" v-model="fileList" :after-read="uploadRead"></van-uploader>
            </div>
          </van-cell-group>
        </div>
        <div style="height: 80px;"></div>
      </div>
    </div>

    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="win-body">
          <div style="position: relative">
            <div class="close-btn" @click="closeDialog">
              <van-image :src="require('@/assets/img/x.png')"></van-image>
            </div>
            <div class="win-text">是否确定为<span style="color:#4f85ff">“{{detailList.userName}}”</span>提供<span style="color:red">“{{ detailList.name }}”</span>服务
              <span v-if="detailList.serviceProfit===0" style="color:red">(无偿)</span>
              <span v-if="detailList.serviceProfit===1" style="color:red">(低偿)</span>
              ?</div>
            <div class="win-bottom">
              <van-button class="win-confirm-btn" @click="confimApplication">确认申请提供服务</van-button>
            </div>
          </div>

        </div>
      </div>
    </van-overlay>
  </div>


</template>

<script>

import topBar from '@/components/topBar/topBar'
import {desensitization} from "@/utils/utils";


export default {

  name: "request-detail",

  components: {
    topBar
  },
  data(){
    return{
      id:null,
      provideUserId:null,
      show:false,
      show_1:false,
      status:null,
      fileList: [],
      description:null,
      rate:3,
      uploadFile:[],
      uploadFileList:[],
      newFileMessages:[],
      message:null,
      detailList: {
      },

      submitData: {
        building:'',
        content:'',
        newFileMessages:[],
        fileMessage: [],
        house:'',
        location:'',
        orgId:0,
        subarea:'',
        type:'',
        unit:'',
        userId:''
      },
    }
  },
  mounted() {
    // console.log(this.$route.params.requestStatus)
    // console.log(this.$route.params.id)
    this.status = this.$route.params.status
    this.id=this.$route.params.id
    // this.provideUserId = this.$route.params.provideUserId
    // this.provideUserId = this.$route.params.provideUserId
    console.log(this.provideUserId)
    console.log(this.id)
    console.log(this.status)
    this.getDataList()
  },
  methods:{
    getDataList(){
      this.fileList = []
      this.$httpApp({
        url:this.$httpApp.adornUrl("/wxapp/app/appserviceapply/info/"+this.id),
        method: "post",
      }).then(({data}) =>{
        console.log(data.appServiceApply)
        this.detailList=data.appServiceApply
        if(this.detailList.status===51){
          this.detailList.mobile = desensitization(this.detailList.mobile)
        }
        if(this.detailList.fileMessages!==null){
          this.detailList.fileMessages.forEach(item => this.fileList.push({url: item.objectUrl,type:item.fileType}))
        }
        console.log(this.fileList)
      })
    },
    confimApplication(){
      this.$httpApp({
        url:this.$httpApp.adornUrl("/wxapp/app/appserviceapply/provideServiceSave"),
        method: "post",
        data:this.$httpApp.adornData({
          "id":this.id,
          "provideUserId":this.$userId
        })
      }).then(({data}) =>{
        // console.log(data)
        if(data.code===0){
          this.$notify({ type: "success", message: "申请成功" });

        }else{
          this.$notify({ type: "danger", message: data.msg });
        }
        this.$router.go(-1)
        this.getDataList()
        this.show=false
      })
    },
    backApplication(){
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appserviceapply/uploadServiceResults"),
        method: "post",
        data:this.$httpApp.adornData({
          id:this.id,
          status:54
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.$notify({ type: "success", message: "撤回成功" });

        }else{
          this.$notify({ type: "danger", message: "撤回失败" });
        }
        this.$router.go(-1)
        this.getDataList()
      });
    },
    uploadResult(){
      console.log(this.message)
      console.log(this.newFileMessages)
      if(this.message===""){
        this.$notify({ type: "danger", message: "描述为空" });
        return
      }
      if(this.newFileMessages.length===0){
        this.$notify({ type: "danger", message: "图片为空" });
        return
      }
      console.log(this.uploadFileList)
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appserviceapply/uploadServiceResults"),
        method: "post",
        data:this.$httpApp.adornData({
          id:this.id,
          serviceDes:this.message,
          status:30,
          newFileMessages:this.newFileMessages,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.$notify({ type: "success", message: "结果上传成功" });
        }else{
          this.$notify({ type: "danger", message: "结果上传失败" });
        }
        this.$router.go(-1)
        this.getDataList()
      });
    },
    application(){
      this.show = true
    },
    closeDialog(){
      this.show = false
    },
    showUploadResult(){
      this.show_1 = true
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    // 删除图片
    uploadDel (file, detail) {
      let i = detail.index
      this.newFileMessages.splice(i, 1)
    },
    uploadRead(params) {

      console.log(params)
      if(Array.isArray(params)){
        var len=params.length;
        for (var i=0;i<len;i++){
          var formData = new FormData();
          formData.append("file", params[i].file);
          formData.append("path", "files/service/temporary");
          this.$http({
            url: this.$http.adornUrl("/wxapp/file/upload"),
            method: "post",
            data: formData,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.newFileMessages.push(data.fileMessage);
              console.log(this.newFileMessages)
              this.$notify({ type: "success", message: "图片上传成功" });
            } else {
              this.$notify({ type: "danger", message: "图片上传失败" });
            }
          });


        }
      }else{
        var formData = new FormData();
        formData.append("file", params.file);
        formData.append("path", "files/service/temporary");
        this.$http({
          url: this.$http.adornUrl("/wxapp/file/upload"),
          method: "post",
          data: formData,
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.newFileMessages.push(data.fileMessage);
            console.log(this.newFileMessages)
            this.$notify({ type: "success", message: "图片上传成功" });
          } else {
            this.$notify({ type: "danger", message: "图片上传失败" });
          }
        });
      }
    },
    checkContent(){
      if (this.description==null){
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss" >
.blue-line{
  position:absolute;
  height:30%;
  margin-top:0.52rem;
  background-color: #0C8DF7;
  width: 0.05rem;
  display: inline-block
}
.base-info-label{
  position:absolute;
  margin-top:0.48rem;
  left:2%;
  display:inline-block;
  font-size: 30px;
  font-weight: bold;
}
.desc{
  background-color: #F5F5F5;
  font-size: 25px;
  border-radius: 1%;
  width: 92%;
  margin-left: 4%
}

.blue-btn{
  background-color:#387FF5;
  color:white;
  width: 92%;
  margin-left: 2%;
  //margin-top: 15%;
  //margin-bottom: 20%;
  position:fixed;
  bottom:10px;
}

.server-info{
  width: 100%;
  height: 0.2rem;
  background-color: #F5F5F5;
  margin-top: 10px;
}

.line-sp{
  width: 100%;
  height: 16px;
  background-color: #F5F5F5;
  margin-top: 20px;
}

.upload-height{
  margin-top: 40px;
  margin-left: 4%;
}
.back-btn{
  width:45%;
  height: 85px;
  //margin-top: 8%;
  //margin-bottom:20%;
  margin-left: 1%;
  background-color: #ed4747;
  color:white;
  position:fixed;
  bottom:25px;
}
.finish-btn{
  width: 45%;
  //margin-top: 8%;
  //margin-bottom:20%;
  margin-left: 50%;
  height: 85px;
  background-color:#387ff5;
  color:white;
  position:fixed;
  bottom:25px;
}

.win-body{
  height:400px;
  width:600px;
  background-color:white;
  position: absolute;
  left: 50%;
  top: 48%;
  margin-top: -200px;
  margin-left: -300px;
  border-radius: 30px;
}

.close-btn{
  position: absolute;
  right: 5%;
  margin-top: 3%;
  height: 40px;
  width: 40px;
}

.win-text{
  position: absolute;
  font-size: 32px;
  margin-top: 20%;
  margin-left: 50px;
  margin-right: 50px;
  line-height: 40px;
}

.win-bottom{
  position:absolute;
  margin-top: 280px;
  left: 120px;
}

.win-confirm-btn{
  border-radius: 60px;
  width: 360px;
  background-color: #387ff5;
  color: white;
}
.van-cell-group::after{
  border-width: 0.02667rem 0 0 0;
}

.close-btn{
  position: absolute;
  right: 5%;
  margin-top: 3%;
  height: 40px;
  width: 40px;
}

</style>
